import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

import Layout from '@components/layout'
import { ListGroup, ListGroupItem } from 'react-bootstrap'

const GroutingAndCuttingPage = () => {
  return (
    <Layout pageTitle="Grouting &amp; Cutting">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/cctv_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Grouting &amp; Cutting</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section text-center">
          <Row>
            <Col>
              <h3>
                Grouting is preventative maintenance which can protect sewers from further infiltration, water level sags, holes, sink holes and more.
              </h3>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section default-text">
          <Row className="align-items-center" lg={2} xs={1}>
            <Col lg={{ order: 'first' }} xs={{ order: 'last' }}>
              <StaticImage
                src="../../../media/cutter.jpg"
              />
            </Col>
            <Col>
              <h3>What is Grouting?</h3>
              <p>
                At Wessuc, we put the comfort of your community first through our non-intrusive repair method. With UV liners that cure in only 8 minutes, our spot repair team can complete the repair quickly. Hours faster than other CIPP liners.
              </p>
              <h3>Grouting Variables</h3>
              <p>
              The application of the grout depends on where the infiltration is coming from. Our grouting unit repairs leaks in laterals, mainline and manhole leaks with the ability to fix pipes up to 600mm in diameter. If roots are evident in a pipe then grouting may be needed.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="default-text">
          <Row className="align-items-center" lg={2} xs={1}>
            <Col>
              <h3>When to Grout</h3>
              <p>
                Water infiltration reduces the wastewater system capacity for treating sewage, by processing unwanted ground water instead. Preventing infiltration reduces the plant loading and effluent discharge volume. 
              </p>
              <p>
                Lining, grouting and spot repair go hand in hand. Each play a role in reducing or eliminating infiltration. Before a repair can be done, the issue needs to be identifies, and the pipe needs to be cleaned. After a liner is installed and laterals are cut out or ‘reinstated’, grouting of that annular space also needs to be completed.
              </p>
              <h3>The Process</h3>
              <ListGroup variant='flush' as="ol" numbered>
                <ListGroupItem as='li'>1. During the cleaning and inspection of the lines defects are identified for review and repair.</ListGroupItem>
                <ListGroupItem as='li'>2. The client is provided the data and videos highlighting the deficiencies.</ListGroupItem>
                <ListGroupItem as='li'>3. Repairs are scheduled and completed.</ListGroupItem>
              </ListGroup>
              <Button href="/contact" variant="primary" className="shadow">I'm Interested!</Button>
            </Col>
            <Col>
              <StaticImage
                src="../../../media/PIPE-TRUCK2.png"
              />
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default GroutingAndCuttingPage